import { FC } from "react"
import styles from './Footer.module.css';
import logo from '../../assets/images/logo.png';
import background from '../../assets/images/footerbg.png';

import { FaTelegram, FaTwitter, FaGithub, FaMedium } from 'react-icons/fa';

const Footer: FC<any> = () => {
    return (
        <div className={`${styles.wrapper} container`}>
            <img src={background} className={styles.footerbg} alt='solana about-bg' />
            <div className={styles.icons}>

            <div className={styles.logoWrapper}>
                <img src={logo} className={styles.logo} alt="solana logo" />
            </div>
                <ul className={styles.socialGroup}>
                    <li><a href="https://t.me/Stakeconomy" target="_blank"><FaTelegram  className={styles.icon}/></a> </li>
                    <li><a href="https://twitter.com/stakeconomy" target="_blank"><FaTwitter className={styles.icon}/></a> </li>
                    <li><a href="https://medium.com/@stakeconomy" target="_blank"><FaMedium className={styles.icon}/></a> </li>
                    <li><a href="https://github.com/stakeconomy" target="_blank"><FaGithub className={styles.icon}/></a> </li>
                </ul>
            </div>
            <p>A dedicated staking node operator on POS Blockchains</p>
        </div>
    )
}

export default Footer
