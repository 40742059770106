import styles from './Calculator.module.css';

import { FC, useEffect, useState } from 'react';
import { NetworkModel } from '../../models/network.model';

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import './non-capsulate.css'

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import RangeInput from './RangeInput';
import ROIcalc from './ROIcalc';

interface CalculatorProps {
    networks: NetworkModel[]
}

const Calculator: FC<CalculatorProps> = ({ networks }) => {
    const networksExtended: any = []
    const [amount, setAmount] = useState([0])
    const [selectedCoin, setSelectedCoin]: any = useState(null)

    const onChange = (val: any) => {
        setAmount(val);
    }
    const selectedCoins = (selected: any) => {
        setSelectedCoin({ ...networks[selected.value] })
    }

    useEffect(() => {
        networks.map((network: NetworkModel) => {
            const item = {
                value: network.id,
                label: <div><img src={network.icon} alt='solana network img' /> {network.name}</div>
            }
            return networksExtended.push(item);
        })
        setTimeout(() => {
            setSelectedCoin({ ...networks[0] })
        }, 2000);
    }, [networks, selectedCoin, amount])

    return (
        <div id="calculate" className={`${styles.wrapper}`}>
            <span className={styles.topHorn}></span>
            <span className={styles.bottomHorn}></span>
                    <h1>CALC & STAKE</h1>
            <div className={styles.calcNstake}>
                <div >
                    <div className={styles.row}>
                        {/* <div className={styles.split}>
                            <div className={styles.title}>Calculate</div>
                            <small>your future wealth</small>
                            <Dropdown
                                options={networksExtended}
                                onChange={selectedCoins}
                                controlClassName={styles.dropDown}
                                menuClassName={styles.dropdownMenu}
                                arrowClosed={<span className={styles.arrow}><MdKeyboardArrowDown /> </span>}
                                arrowOpen={<span className={styles.arrow}> <MdKeyboardArrowUp /></span>}
                                placeholder="Select an option" />
                        </div> */}
                        {selectedCoin && <div className={styles.split} style={{ marginLeft: '20%' }}>
                            <div className={styles.title}>{selectedCoin.name}</div>
                            <small>{selectedCoin.description}</small>
                            <div className={styles.coinInfo} >
                                <img src={selectedCoin.icon} alt='solana selected network img' />
                                <div className={styles.stats}>
                                    <div className={styles.price}>{selectedCoin.price}$</div>
                                    <div className={styles.apy}>APY ~{selectedCoin.apy}%</div>
                                </div>
                            </div>
                        </div>}
                    </div>

                    <RangeInput amount={amount} onChange={onChange} />
                    {selectedCoin && <ROIcalc coin={selectedCoin} amount={amount[0]} price={selectedCoin.price} />}
                </div>
                <div >
                    <iframe
                        id="staking-kiwi-widget-0"
                        scrolling="no"
                        frameBorder="0"
                        allowTransparency={true}
                        allowFullScreen={true}
                        style={{ position: 'static', visibility: 'visible', width: '430px', height: '600px' }}
                        title="Staking Kiwi widget"
                        src="https://widget.staking.kiwi/?validator=GNZ1PAAS33davY4Q1BMEpZEpVBtRtGvSpcTH5wYVkkVt&theme=dark"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}

export default Calculator
