import { FC, useState } from "react"
import styles from './faq.module.css';
import { VscChromeClose } from 'react-icons/vsc';
import background from '../../assets/images/faqbg.png';
import parse from 'html-react-parser'
interface Question {
    id: number;
    title: string;
    desciption: string;
    open: boolean;
}
const Faq: FC<any> = () => {
    const questionList: Question[] = [{
        id: 0,
        title: 'How to stake with Stakeconomy?',
        desciption: `It’s best to stake your SOL tokens with a ledger device through the website solflare.com.
        We have created a complete how-to guide on how to stake 
         <a href='https://stakeconomy.medium.com/how-to-stake-your-sol-on-solflare-com-with-your-ledger-nano-s-x-4ed1ffb8c83f' target='_blank'>here<a/>
        `,
        open: false
    },
    {
        id: 1,
        title: 'How long does it take to stake and unstake SOL?',
        desciption: 'When you stake your SOL tokens at one of our validators the stake needs to be warmed up before it activates. The stake will be activated at the start of a new epoch. For one epoch to complete takes around ~48 hours. Same rules apply for unstaking. When you unstake your tokens. The stake needs to be cooled down and will be released when a new epoch starts.',
        open: false
    },
    {
        id: 2,
        title: 'Is staking income automatically staked?',
        desciption: 'Yes, staking income is compounded automatically. You will need to split the stake and withdraw the rewards for it to be liquid again.',
        open: false
    },
    {
        id: 3,
        title: 'Do I maintain custody of my SOL tokens?',
        desciption: 'Yes, you maintain full custody of your SOL tokens. When you stake your tokens your wallet address will be the Stake and Withdraw Authority Address of your stake. Only you can unstake and withdraw your tokens.',
        open: false
    },
    {
        id: 4,
        title: 'Can I lose my stake or staking rewards?',
        desciption: 'You cannot lose your stake. But when a validator is down you will not be earning staking rewards. We will do everything that we can to prevent validator downtime.',
        open: false
    },
    {
        id: 5,
        title: 'What is Solana?',
        desciption: 'Solana is a layer 1, Proof-of-Stake (PoS) blockchain with a Practical Byzantine Fault Tolerance (pBFT) – derived consensus mechanism offering support for more than 50,000 transactions per second (TPS).',
        open: false
    }


]
    const [questions, setQuestion] = useState(questionList)
    const toggleQ = (id: number) => {
        setQuestion(
            questions.map((question: Question) =>
                question.id === id ? { ...question, open: !question.open } : question
            )
        )
    }

    return (
        <div id='faq' className={`${styles.wrapper} container`}>
            <img src={background} className={styles.faqbg} alt='solana about-bg' />
            <div className={styles.faqHead}>
                <h1>FAQs</h1>
                {/* <p>Your text hereyour text heext here text hereyour text hereyour </p> */}
            </div>
            <div className={styles.qList}>
                {questions.map((question: Question, index: number) =>
                    <div className={styles.qItem} key={index}>
                        <div className={styles.qHead}>
                            <h1>{question.title}</h1>
                            <button onClick={() => toggleQ(index)}>
                                <VscChromeClose className={`${question.open ? '' : styles.rotate} ${styles.icon}`} />
                            </button>
                        </div>
                        {question.open && <p>{parse(question.desciption)}</p>}
                    </div>
                )}

            </div>
        </div>
    )
}

export default Faq
