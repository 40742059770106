import { FC } from 'react';
import { NetworkModel } from '../../models/network.model';
import Network from './Network';
import styles from './Stake.module.css';

interface StakeProps {
    networks: NetworkModel[]
}
const Stake: FC<StakeProps> = ({ networks }) => {

    return (
        <div id="delegate" className={`${styles.stakeIntro} container`}>
            <div className={styles.stakeHead}>
                <h1>stake</h1>
                <p>We run validators on the projects listed below</p>
            </div>
            <div className={styles.networks}>
                {networks.map((network: NetworkModel, index: number) => (
                    <Network key={index} network={network} />
                ))}
            </div>
        </div>
    )
}

export default Stake
