import { FC } from "react"
import logo from '../../assets/images/logo.png';
import styles from './Header.module.css';
interface HeaderProps {

}
const Header: FC<HeaderProps> = () => {
    const scrollTo = (ev: any) => {
        const elId = ev.target.innerHTML;
        const element = document.getElementById(elId);
        if(element){
            element.scrollIntoView({ behavior: "smooth" })
        }
    }
    return (
        <header className={`${styles.header} container`}>
            <img src={logo} className={styles.logo} alt="solana stakeconomy logo" />
            <nav className={styles.nav} onClick={(e) => scrollTo(e)}>
                <div>about</div>
                <div>calculate</div>
                {/* <div>blog</div> */}
                <div>faq</div>
                <div><a target="_blank" style={{textDecoration:'none', color: 'white'}} href="https://metrics.stakeconomy.com/">monitor</a></div>
                <div className={styles.delegateBtn}>delegate</div>
            </nav>
        </header>
    )
}

export default Header
