import { FC } from "react"

interface Validator{
    Validator: any;
}
const Validator:FC<Validator> = ({Validator}) => {
    return (
        <div className='tableRow'>
        <span>{Validator.name}</span>
        <div className='tableAddress'>{Validator.address}
        <button onClick={() => {
            window.open(`https://solanabeach.io/validator/${Validator.address}`)
        }}>stake
        </button>
            </div>
    </div>
    )
}

export default Validator
