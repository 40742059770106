import { useState, useEffect, FC } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "./StakeInfo.css";
import { NetworkModel } from '../../models/network.model';
import Validator from './Validator';
interface StakeInfoProps {
    network: NetworkModel;
}
const StakeInfo: FC<StakeInfoProps> = ({ network }) => {
    const [open, setOpen] = useState(false);

    const closeModal = () => setOpen(false);
      useEffect(() => {
          if(open){
            document.body.style.overflow = 'hidden';
          }else{
            document.body.style.overflow = 'unset';
          }
       
      }, [open])
    return (
        <div>
            <button type="button" className="button" onClick={() => setOpen(o => !o)}>
                Stake
        </button>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className='popup-content'>
                    <div className='popup-wrapper'>
                        <div className='infoHead'>
                            <div className='infoTitle'>
                                {network.name} validator
                        </div>
                            <div className='infoNetworkIcon'>
                                <img src={network.validatorInfo.icon} alt="solana validator" />
                            </div>
                        </div>
                        <div className='infoBody'>
                            <div className='invesment-stats'>
                                <div className='stats'>
                                    <div className='stat-label'>
                                        Expected Reward Rate
                                    </div>
                                    <div className='stat-value'>
                                    ~{network.validatorInfo.apy}%
                                    </div>
                                </div>
                                <div className='stats'>
                                    <div className='stat-label'>
                                        Commission
                                    </div>
                                    <div className='stat-value'>
                                        {network.validatorInfo.commition}
                                    </div>
                                </div>
                                <div className='stats'>
                                    <div className='stat-label'>
                                        Payout Frequency
                                    </div>
                                    <div className='stat-value'>
                                        {network.validatorInfo.payoutFrequency}
                                    </div>
                                </div>
                                <div className='stats'>
                                    <div className='stat-label'>
                                        Unbonding period
                                    </div>
                                    <div className='stat-value'>
                                        {network.validatorInfo.unstakeColdDown}
                                    </div>
                                </div>
                            </div>
                            <div className='tableHead'>
                                <span>display name</span>
                                <span>validator address</span>
                            </div>
                            {network.validatorInfo.nodes.map((node: any, index: number) =>
                                <Validator key={index} Validator={node} />
                            )}
                        </div>
                        <div className='validator-btn-wrap'>
                            <button className="close" onClick={closeModal}>close</button>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default StakeInfo;