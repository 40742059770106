import styles from './Slogen.module.css';
const Slogen = () => {
    const scrollTo = () => {

        const element = document.getElementById('delegate');
        if(element){
            element.scrollIntoView({ behavior: "smooth" })
        }
    }
    return (
        <div className={`${styles.slogen} container`}>
            <h4>High perfomance solana validator nodes</h4>
            <h1>GROW YOUR ASSETS</h1>
            <p><span>Stakeconomy</span> help you delegate your assets without compromise on security, transparency or performance</p>
            <button onClick={ () => scrollTo()}>networks</button>
        </div>
    )
}

export default Slogen
