import { FC, useState } from "react"
import Slide from './Slide'
import styles from './About.module.css';

import background from '../../assets/images/aboutusbg.png';

import about_icon1 from '../../assets/images/about_icon1.png';
import about_icon2 from '../../assets/images/about_icon2.png';
import about_icon3 from '../../assets/images/about_icon3.png';
import about_icon4 from '../../assets/images/about_icon4.png';
import { AboutModel } from "../../models/about.model";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const About: FC = () => {
    const aboutData: AboutModel[] = [
        {
            img: about_icon1,
            title: 'Validator Services',
            desc: `We have over 18 years experience with mission critical infrastructure
             and high performance workloads. We run our validator services from high
            available Tier III Data Centers in the Netherlands.`
        },
        {
            img: about_icon2,
            title: 'High Available',
            desc: `We use enterprise infrastructure with 99.9% uptime SLAs.
             We are using the latest hardware to ensure performance and redundant 
             setup to have the best availability.
              We are known for running the lowest skip rates
               and highest uptimes in the industry.`
        },
        {
            img: about_icon3,
            title: 'Secure by Default',
            desc: `Security is not a component but something that is fundamental in our services.
              We ensure that security is taken into consideration in every
              step of every process within our company.
              We strive to be best in class.`
        },
        {
            img: about_icon4,
            title: 'Monitoring and Alerting',
            desc: `We monitor all our validators real time with our inhouse developed 
                <a target="_blank" href='https://metrics.stakeconomy.com'>metrics dashboard</a> that we
                made available for the Solana Community to use.
                We measure all relevant validator performance metrics like
                skiprate, server load, cpu, ram and disk usage. 
                We alert our 24x7 support team when validator metrics are out of the normal.`
        },
    ]
    const [count, setCount] = useState(0);
    const sliderOptions = [0, -100, -200, -300]
    const scrollFill = [25, 50, 75, 100]
    const onNextSlide = () => {
        if (count === 3) {
            setCount(0)
        } else {
            setCount(count + 1)
        }
    }
    const onPrevSlide = () => {
        if (count === 0) {
            setCount(3)
        } else {
            setCount(count - 1)
        }
    }
    return (
        <div id="about" className={`${styles.wrapper} container`}>
            <img src={background} className={styles.aboutbg} alt='about-bg solana' />
            <div className={`${styles.divider}`}>
                <h1>ABOUT <br />OUR <br /> SERVICE</h1>
            </div>
            <div className={`${styles.box} ${styles.divider}`}>
                <div className={styles.data} style={{ left: `${sliderOptions[count]}%` }}>
                    {aboutData.map((about, index) => (
                        <Slide key={index} about={about} />
                    ))}
                </div>
                <div className={styles.slideBar} style={{ width: `${scrollFill[count]}%` }}></div>
                <div className={styles.slideControls}>
                    <button type='button' onClick={onPrevSlide}>
                        <MdKeyboardArrowLeft />
                    </button>
                    <button type='button' onClick={onNextSlide}>
                        <MdKeyboardArrowRight />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default About
