import { FC } from 'react';
import { Range, getTrackBackground } from 'react-range';
import styles from './RangeInput.module.css';
const RangeInput: FC<any> = ({ amount, onChange }) => {
    const STEP = 1;
    const MIN = 0;
    const MAX = 10000;
    return (
        <div className={styles.rangeWrapper}>
            <Range
                values={amount}
                step={STEP}
                min={MIN}
                max={MAX}
                onChange={(value) => onChange(value)}
                renderTrack={({ props, children }) => (
                    <div
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                        style={{
                            ...props.style,
                            height: '6px',
                            width: '100%',
                            marginBottom: '45px',
                            backgroundColor: '#C9D8FF'
                        }}
                    >
                        <div
                            ref={props.ref}
                            style={{
                                height: '5px',
                                width: '100%',
                                borderRadius: '4px',
                                background: getTrackBackground({
                                    values: amount,
                                    colors: ['#548BF4', '#C9D8FF'],
                                    min: MIN,
                                    max: MAX
                                }),
                                alignSelf: 'center'
                            }}
                        >
                            {children}
                        </div>
                    </div>
                )}
                renderThumb={({ props, isDragged }) => (
                    <div className={styles.thumb}
                        {...props}

                    >

                    </div>
                )}
            />
            <output>
                <div className={styles.outputWrapper}>
                    <span className={styles.pAmount}>ENTER YOUR AMOUNT</span> <span className={styles.amount}>{amount[0].toLocaleString()}</span>
                </div>
            </output>
        </div>
    )
}

export default RangeInput
