import { FC } from "react"
import { AboutModel } from "../../models/about.model"
import styles from './Slide.module.css';
import parse from 'html-react-parser'
interface SlideProps {
    about: AboutModel
}
const Slide: FC<SlideProps> = ({ about }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrap}>
                <img src={about.img} alt='validator about img solana' />
            </div>
            <h3>{about.title}</h3>
            <p>{parse(about.desc)}</p>
        </div>
    )
}

export default Slide
