import Header from './components/Header';
import Slogen from './components/Slogen';
import Stake from './components/Stake';
import About from './components/About';
import Calculator from './components/Calculator';

import styles from './App.module.css'
import background from './assets/images/topbg.png';


import solanaIcon from './assets/images/solana_icon.png'
import solanaValidatorIcon from './assets/images/solana_transparent.png'

import { useEffect, useState } from 'react';
import { PriceFeed } from './services/priceFeed';
import Faq from './components/FAQ';
import Footer from './components/Footer';

import ReactGA from 'react-ga';
const TRACKING_ID = "UA-188995005-2"; // YOUR_OWN_TRACKING_ID


function App() {
  const [networks, setNetwork] = useState([
    {
      id: 0,
      icon: solanaIcon,
      apy: 6,
      name: 'solana',
      description: 'proof of history blockchain',
      active: true,
      price: 14,
      validatorInfo: {
        icon: solanaValidatorIcon,
        nodes: [
          {
            id: 0,
            name: 'stakeconomy',
            address: 'GNZ1PAAS33davY4Q1BMEpZEpVBtRtGvSpcTH5wYVkkVt'
          }
        ],
        apy: 6,
        commition: '0%',
        payoutFrequency: '1 epoch(~3 days)',
        unstakeColdDown: 'end of epoch'
      }
    },
    // {
    //   id: 1,
    //   icon: cosmosIcon,
    //   apy: 8,
    //   name: 'cosmos',
    //   description: 'the decentralize internet',
    //   active: false,
    //   price: 22
    // },
    // {
    //   id: 2,
    //   icon: kavaIcon,
    //   apy: 5,
    //   name: 'kava',
    //   description: 'new financial system',
    //   active: false,
    //   price: 6
    // }
  ]);
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    getCoinsPrice();
    // return () => {
    //   cleanup
    // }
  }, [])
  const getCoinsPrice = async () => {
    const priceFeed = new PriceFeed();
    const coinInfo = await priceFeed.getPriceList()
    setNetwork(
      networks.map((network: any, i: any) => {
        const coinName = Object.keys(coinInfo.data)[i];
        return network.name === coinName ? { ...network, price: coinInfo.data[coinName]?.usd } : network
      }
      )
    )

  }
  return (
    <div style={{ position: 'relative' }}>
      <img className={styles.topBg} src={background} alt='top-bg solana' />
      <Header />
      <Slogen />
      <Stake networks={networks} />
      <About />
      <Calculator networks={networks} />
     
      <Faq />
      <Footer />
    </div>
  );
}

export default App;
