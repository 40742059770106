import { FC } from "react"
import { NetworkModel } from "../../models/network.model";
import styles from './ROIcalc.module.css';
interface ROIcalcProps {
    coin: NetworkModel,
    amount: number,
    price: number
}
const ROIcalc: FC<ROIcalcProps> = ({ coin, amount, price }) => {
    const calcCoinROI = (coin: NetworkModel, amount: number, days: number) => {
        return ((coin.apy / 100) * amount / days).toFixed(2);
    }
    const calcUSDROI = (coin: NetworkModel, amount: number, days: number) => {
        return ((coin.apy / 100) * amount / days * price).toFixed(2);
    }
    return (
        <div className={styles.incomeCalc}>
            <div className={styles.incomeBox}>
                <div className={styles.incomeWrap}>
                    <div className={styles.holding}>
                        <label>COINS</label><div>{calcCoinROI(coin, amount, 365)}</div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.holding}>
                        <label>USD</label><div>{calcUSDROI(coin, amount, 365)}$</div>
                    </div>
                </div>
                <div className={styles.incomeDuration}>
                    Daily Income
                </div>
            </div>
            <div className={styles.incomeBox}>
                <div className={styles.incomeWrap}>
                    <div className={styles.holding}>
                        <label>COINS</label><div>{calcCoinROI(coin, amount, 12)}</div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.holding}>
                        <label>USD</label><div>{calcUSDROI(coin, amount, 12)}$</div>
                    </div>
                </div>
                <div className={styles.incomeDuration}>
                    Monthly Income
                </div>
            </div>
            <div className={styles.incomeBox}>
                <div className={styles.incomeWrap}>
                    <div className={styles.holding}>
                        <label>COINS</label><div>{calcCoinROI(coin, amount, 1)}</div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.holding}>
                        <label>USD</label><div>{calcUSDROI(coin, amount, 1)}$</div>
                    </div>
                </div>
                <div className={styles.incomeDuration}>
                    Yearly Income
                </div>
            </div>
        </div>
    )
}
ROIcalc.defaultProps = {
    amount: 0,
    price: 0
}

export default ROIcalc
