import { FC } from "react"
import { NetworkModel } from "../../models/network.model";
import styles from './Network.module.css';
import StakeInfo from './StakeInfo';


interface networkProps {
    network: NetworkModel
}
const Network: FC<networkProps> = ({ network }) => {
    return (
        <div className={styles.network}>
            <div className={styles.wrapper}>
                <img src={network.icon} alt='solana network icon' />
                <div className={styles.networkApy}>APY ~{network.apy}%</div>
                <div className={styles.networkName}>{network.name}</div>
                <div className={styles.networkStatus}><span className={`${network.active ? styles.active : ''}`}></span>
                    {network.active ? "network is live" : 'Network is in development'}
                </div>
                {/* button */}
                <StakeInfo network={network}/>
            </div>
        </div>
    )
}

export default Network
